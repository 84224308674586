<template>
  <v-tabs color="transparent">
    <v-layout row wrap>
      <v-flex xs9>
        <v-tab>{{ $t('_userProfile') }}</v-tab>
        <v-tab>{{ $t('_contactPreferences') }}</v-tab>
        <v-tab>{{ $tc('_hardware') }}</v-tab>
      </v-flex>
      <v-flex xs3> </v-flex>
      <v-flex xs12 v-if="isLoading > 0">
        <v-progress-linear indeterminate color="green"></v-progress-linear>
      </v-flex>
    </v-layout>

    <v-tab-item class="pa-1">
      <v-card style="background: white" class="elevation-3">
        <v-card-text color="white">
          <v-subheader class="pa-0 text-uppercase">{{ $t('_user') }}</v-subheader>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field v-model="email" :label="$t('_email')" class="pl-4" disabled></v-text-field>
            <v-text-field
              v-model="displayName"
              :rules="[rules.required, rules.max20]"
              :label="$t('_name')"
              class="pl-4"
            >
            </v-text-field>
            <v-subheader class="pa-0 text-uppercase">{{ $t('_password') }}</v-subheader>
            <v-switch :label="$t('_updatePasswordQuestion')" class="ma-0 pl-4" v-model="isPasswordUpdate"></v-switch>
            <div v-if="isPasswordUpdate">
              <v-text-field
                v-model="password"
                :append-icon="showPw1 ? 'visibility_off' : 'visibility'"
                :rules="[rules.required, rules.min12]"
                :type="showPw1 ? 'text' : 'password'"
                name="input-10-2"
                :label="$t('_newPassword')"
                :hint="$t('_atLeastChars', { num: 12 })"
                class="input-group--focused"
                @click:append="showPw1 = !showPw1"
                ref="newPassword"
              >
              </v-text-field>
              <v-text-field
                :append-icon="showPw2 ? 'visibility_off' : 'visibility'"
                :rules="[rules.required, rules.pwMatch]"
                :type="showPw2 ? 'text' : 'password'"
                name="input-10-2"
                :label="$t('_newPasswordConfirm')"
                :hint="$t('_atLeastChars', { num: 12 })"
                class="input-group--focused"
                @click:append="showPw2 = !showPw2"
                ref="newPasswordCheck"
              >
              </v-text-field>
            </div>
            <v-subheader class="pa-0 text-uppercase">{{ $t('_locale') }}</v-subheader>
            <v-select class="pl-4" :items="localeOptions" item-value="code" v-model="$i18n.locale">
              <template slot="selection" slot-scope="data">
                <v-avatar>
                  <img :src="data.item.flag" class="tw-w-8 tw-h-8" />
                  <span class="tw-ml-3">
                    {{ data.item.name }}
                  </span>
                </v-avatar>
              </template>
              <template slot="item" slot-scope="data">
                <v-list-tile-content>
                  <div class="tw-w-24 tw-text-left tw-absolute tw-left-0">
                    <div class="tw-flex tw-flex-row tw-gap-2 tw-pl-1 tw-items-center">
                      <img :src="data.item.flag" class="tw-w-8 tw-h-8" />
                      <span>{{ data.item.name }}</span>
                    </div>
                  </div>
                </v-list-tile-content>
              </template>
            </v-select>

            <v-alert v-model="showPwChangeWarning" type="error" transition="scale-transition" dismissible outline>
              {{ $t('_passwordChangeWarning') }}
              <v-btn :disabled="!valid" @click="logOut" color="secondary" small>
                {{ $t('_login') }}
              </v-btn>
            </v-alert>
            <v-btn
              color="success"
              small
              :disabled="!valid"
              @click="updateUser(displayName, photoUrl, password, locale)"
            >
              {{ $t('_save') }}
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-tab-item>

    <v-tab-item class="pa-1">
      <contact-preferences />
    </v-tab-item>

    <v-tab-item class="pa-1">
      <hardware-wallet />
    </v-tab-item>
  </v-tabs>
</template>

<script>
import gql from 'graphql-tag';

import { locales } from '../../contracts/locales';
import { MUT_SET_USER, MUT_SNACKBAR } from '../../store';
import HardwareWallet from '../wallets/HardwareWallet';
import ContactPreferences from './ContactPreferences';
export default {
  props: ['updateUserDisplay', 'appRefs', 'logOut', 'updateUsersTable'],
  data() {
    return {
      valid: true,
      isPasswordUpdate: false,
      showPw1: false,
      showPw2: false,
      showPwChangeWarning: false,
      displayName: '',
      photoUrl: '',
      localeOptions: locales,
      locale: '',
      password: '',
      rules: {
        required: (v) => !!v || this.$t('_required'),
        min8: (v) => v.length >= 8 || this.$t('_minEightChars'),
        min12: (v) => v.length >= 12 || this.$t('_minChars', { num: 12 }),
        max20: (v) => v.length <= 20 || this.$t('_maxTwentyChars'),
        pwMatch: (v) => v === this.password || this.$t('_passwordsDontMatch'),
      },
      isLoading: false,
    };
  },
  methods: {
    updateUserLocale() {
      localStorage.setItem('locale', this.$i18n.locale);
    },
    async updateUser(newName, photoUrl, password, locale) {
      this.isLoading = true;

      // update the user locale
      this.updateUserLocale();

      const user = {
        displayName: newName,
        photoUrl,
      };

      if (password) {
        user.password = password;
      }

      const resp = await this.$apollo.mutate({
        // Query
        mutation: gql`
          mutation ($userId: ID!, $user: UserInput!) {
            updateUser(userId: $userId, user: $user) {
              success
              errors
            }
          }
        `,
        // Parameters
        variables: {
          userId: this.$store.state.user.uid || this.$store.state.user.id,
          user,
        },
      });

      if (resp && !resp.data?.updateUser?.errors) {
        this.isLoading = false;
        this.$store.commit(MUT_SNACKBAR, {
          color: 'success',
          message: 'Successfully updated',
        });
        this.$store.commit(MUT_SET_USER, {
          ...this.$store.state.user,
          displayName: user.displayName,
          photoUrl: user.photoUrl,
        });
      } else if (resp && resp.data?.updateUser?.errors && resp.data?.updateUser?.errors?.length > 0) {
        this.isLoading = false;
        this.$store.commit(MUT_SNACKBAR, {
          color: 'error',
          message: 'Problem updating User profile: ' + resp.data.updateUser.errors.join('<br />'),
        });
      }
    },
  },
  mounted() {
    this.locale = this.$i18n.locale;
    this.displayName = this.$store.state.user.displayName;
  },
  computed: {
    email() {
      return this.$store.state.user.email;
    },
  },
  watch: {},
  components: {
    HardwareWallet,
    ContactPreferences,
  },
};
</script>

<style scoped>
.readonlytext {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
